import React from 'react';

const VideoPreview = () => {
  return (
    <iframe
      width="100%"
      height="100%"
      src="https://www.youtube.com/embed/mTdL3_kNXOI?autoplay=1"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autohide"
      allowFullScreen
    />
  );
};
export default VideoPreview;
