import React from 'react';
import { Box } from '@mui/material';
import Title from 'pages/Home/components/Title';
import LinkToPageWithMedia from 'pages/Home/components/LinkToPageWithMedia';
import AboutCompany from 'pages/Home/components/AboutCompany';
import PoweredBy from 'pages/Home/components/PoweredBy';

const HomeMainInfo = () => {
  return (
    <Box
      sx={{ flexGrow: 2 }}
      padding={2}
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <Title />
        <AboutCompany />

        <Box>
          <LinkToPageWithMedia
            text="See our presentation"
            url="/presentation"
          />
          <LinkToPageWithMedia text="See our video" url="/video" />
        </Box>
      </Box>

      <PoweredBy />
    </Box>
  );
};

export default HomeMainInfo;
