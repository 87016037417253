import React from 'react';
import './App.css';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import { Typography } from '@mui/material';
import Header from 'UI/Header';
import Home from 'pages/Home';
import VideoPreview from 'pages/VideoPreview';
import PresentationPreview from 'pages/PresentationPreview';
import ContactUs from 'pages/ContactUs';

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/video" element={<VideoPreview />} />
        <Route path="/presentation" element={<PresentationPreview />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route
          path="*"
          element={
            <div>
              <Typography variant="h2" align="center">
                Page is not found
              </Typography>
              <Typography variant="body1" align="center">
                <Link to="/">Go to Home </Link>
              </Typography>
            </div>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
