import React from 'react';
import { Box, Typography } from '@mui/material';
import { config } from 'style-config';

const AboutCompany = () => {
  return (
    <Box mb={3}>
      <Box display="flex">
        <Box className="text-with-line" />
        <Box ml={1}>
          <Typography variant="h6">
            <Box color={config.MainColor} fontWeight="bold" component="span">
              KeepToShip
            </Box>{' '}
            is an Uber-Like system (SAAS) that connects
            <ul style={{ margin: 0 }}>
              <li>online sellers and</li>
              <li>people who keep and ship goods for them</li>
            </ul>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutCompany;
