import React from 'react';
import { Box, Link as LinkMaterial, Typography } from '@mui/material';

const PoweredBy = () => {
  return (
    <Box ml="auto" width="100%">
      <Box display="flex" alignItems="center" sx={{ gap: 2, float: 'right' }}>
        <Typography variant="h4">Powered By</Typography>
        <LinkMaterial href="https://rollun.com">
          <img
            src="https://rollun-cdn.sfo2.digitaloceanspaces.com/images/logo.png"
            alt="Rollun logo"
          />
        </LinkMaterial>
      </Box>
    </Box>
  );
};

export default PoweredBy;
