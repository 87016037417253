import React, { FC } from 'react';
import { Box } from '@mui/material';

type SplitComponentChild = {
  component: JSX.Element;
  flexGrow: number;
};
interface SplitView {
  first: SplitComponentChild;
  second: SplitComponentChild;
}

const SplitView: FC<SplitView> = ({ first, second }) => {
  return (
    <Box
      display="flex"
      sx={{ height: 'calc(100%)' }}
      justifyContent="space-between"
    >
      <Box sx={{ flexGrow: first.flexGrow }}>{first.component}</Box>
      <Box sx={{ flexGrow: second.flexGrow }}>{second.component}</Box>
    </Box>
  );
};

export default SplitView;
