import React from 'react';
import '../../index.css';
import SplitView from 'pages/Home/components/SplitView';
import HomeImage from 'pages/Home/components/HomeImage';
import HomeMainInfo from 'pages/Home/components/HomeMainInfo';

const Home = () => {
  return (
    <SplitView
      first={{
        component: (
          <HomeImage
            src="https://rollun-cdn.sfo2.digitaloceanspaces.com/keeptoship/s_man_comp_goodspng.png"
            alt="Some keep to ship"
            width="600px"
            height="100%"
          />
        ),
        flexGrow: 1,
      }}
      second={{
        component: <HomeMainInfo />,
        flexGrow: 2,
      }}
    />
  );
};

export default Home;
