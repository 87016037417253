import React from 'react';
import { Box, Link, Typography } from '@mui/material';

const ContactUs = () => {
  return (
    <Box padding={4} display="flex" sx={{ gap: 1 }} alignItems="center">
      <img
        src="https://rollun-cdn.sfo2.digitaloceanspaces.com/keeptoship/rollun-ceo.png"
        alt=""
        width={300}
        height={300}
      />
      <Box ml={2}>
        <Typography variant="h4">Natalia Gretchukha</Typography>
        <Typography variant="h4">CEO, MD, MBA (Duke)</Typography>
        <Typography variant="h4">+1 929 214 1374</Typography>
        <Typography variant="h4">grechuha.n@gmail.com</Typography>
        <Link variant="h4" href="https://www.linkedin.com/in/natalia-grechuha/">
          Linkedin
        </Link>
      </Box>
    </Box>
  );
};

export default ContactUs;
