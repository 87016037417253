import React from 'react';
import { Box, Typography } from '@mui/material';
import { config } from 'style-config';

const Title = () => {
  return (
    <Box mb={3}>
      <Box color={config.MainColor} fontSize={140} fontWeight={40}>
        Keep To Ship
      </Box>
      <Typography variant="h4" align="right">
        Decreases shipping time and costs
      </Typography>
    </Box>
  );
};

export default Title;
