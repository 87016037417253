import React, { FC } from 'react';
import { config } from 'style-config';
import { Box, IconButton, Typography } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';
import { Link } from 'react-router-dom';

interface LinkToPageWithMediaProps {
  text: string;
  url: string;
}

const LinkToPageWithMedia: FC<LinkToPageWithMediaProps> = ({ text, url }) => {
  return (
    <Link to={url} style={{ textDecoration: 'none', color: config.MainColor }}>
      <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
        <IconButton sx={{ color: config.MainColor }}>
          <PlayArrow sx={{ fontSize: 60 }} />
        </IconButton>
        <Typography variant="h6">
          <Box fontWeight="bold">{text}</Box>
        </Typography>
      </Box>
    </Link>
  );
};

export default LinkToPageWithMedia;
