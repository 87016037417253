import React from 'react';

const PresentationPreview = () => {
  return (
    <iframe
      title="Presentation v2"
      src={`https://view.officeapps.live.com/op/embed.aspx?src=https://rollun-cdn.sfo2.digitaloceanspaces.com/keeptoship/KeepToShip-presentation.pptx`}
      width="100%"
      height="100%"
      frameBorder="0"
    />
  );
};

export default PresentationPreview;
